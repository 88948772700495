import React from "react";
import hand from "../../images/hand.svg";
import banner from "../../images/dashboard-banner.jpg";
import "./style.css";

const DashboardBanner = ({ userDetails, applyNow }) => {
  return (
    <section
      className="dashboard-banner"
      style={{ backgroundImage: `url(${banner})` }}
    >
      <div className="container">
        <div className="user-name-block">
          <span>
            <img src={hand} alt="hand symbol" />
          </span>
          <span>
            <h2>Hello {userDetails ? userDetails.firstName : "User"},</h2>
            <p>Here’s your application history.</p>
          </span>
        </div>
        {applyNow && <div className="apply-now-block">{applyNow}</div>}
      </div>
    </section>
  );
};

export default DashboardBanner;
