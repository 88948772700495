/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { API_RequestResetPasswordLink } from "../../api/signing-request";
import { FORM_PLACEHOLDER } from "../../constants/placeholder";
import Input from "../Input";
import Button from "../Button";

const ForgotPasswordForm = (props) => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formMessage, setFormMessage] = useState("");

  const onhandleSubmit = () => {
    setIsLoading(true);
    API_RequestResetPasswordLink({
      "SearchKeyData": {
        SchemaName: "EmailAddress",
        Value: email
      },
      RecipientField: {
        SchemaName: "EmailAddress"
      }
    })
      .then(function (response) {
        setIsLoading(false);
        setFormMessage("An email with a link has been sent successfully");
      })
      .catch(function (error) {
        setIsLoading(false);
        setFormMessage(error.response.data.ExceptionMessage);
      });
  };

  return (
    <>
      <div className="form-skeleton">
        <div className="form-title-wrapper">
          <h3>Forgot Password</h3>
          <p>Please enter your email, We will send a password reset link.</p>
        </div>
        {formMessage && <p className="form-message"> {formMessage}</p>}
        <form
          action=""
          method="post"
          className="form-wrapper-box"
          onSubmit={(e) => {
            e.preventDefault();
            onhandleSubmit();
          }}
        >
          <Input
            type="email"
            handleChange={(value) => {
              setEmail(value);
            }}
            handleBlur={() => {}}
            value={email}
            name="Email"
            error={false}
            label="Email"
            mandatory={true}
            placeholder={FORM_PLACEHOLDER.EMAIL}
          />

          <div className="form-group">
            <Button
              disabled={!email}
              type="submit"
              className="btn-primary submit-button"
              text="Send Password Reset Link"
              status={isLoading}
            />
          </div>
        </form>
        <div className="form-footer">
          <div>
            <a
              href="javascript:void(0)"
              onClick={() => props.changeWidgetState("LOGIN")}
            >
              Sign In
            </a>
          </div>
          <div>
            Don't have an account?{" "}
            <a
              href="javascript:void(0)"
              onClick={() => props.changeWidgetState("REGISTER")}
            >
              Create One
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPasswordForm;
