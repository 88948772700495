import React from "react";
const ApplicationDeclaration = ({ application, activity, parentInfo }) => {
  return (
    <div className="application-declaration-section">
      <p>Declaration of Applicant</p>
      <div className="application-details-block">
        <p>
          I, {application && application.FirstName} Son/Daughter of{" "}
          {parentInfo && parentInfo.fatherName} is applying for admission to
          NIMS University and hereby declare that:
        </p>

        <p>
          All the information submitted to NIMS University otherwise in any
          manner or whatsoever, is complete and factually correctand accurate to
          the best of my knowledge. In case it is found that I have concealed,
          suppressed or distorted any information/fact itwould render me
          ineligible for admission, and my admission to NIMS University could be
          cancelled and I shall have no claim whatsoever against the University.
        </p>

        <p>
          I understand that NIMS University reserves the right to reject my
          application/admission entirely at the discretion of the University
          authorities.
        </p>

        <p>
          I understand that in the event of my not fulfilling any requirements
          prescribed by the University, the University shall be entitled
          tocancel my admission at any time besides other actions as considered
          necessary by the University authorities.
        </p>

        <p>
          In the event that I am awarded a scholarship, I understand that it can
          be withdrawn at any time at the sole discretion of the management of
          NIMS University.
        </p>

        <p>
          In case of any dispute, the decision of the competent authority of the
          University shall be final and binding on me.
        </p>
        <div className="signature-block">
          <p>Signature of the Applicant</p>
          <img
            src={
              activity &&
              activity[4].CustomObjectFormProperties.FieldProperties
                .FormMetaData[1].FileURL
            }
            alt="signature"
            crossOrigin="anonymous"
          />
        </div>
        <div className="declaration-accepted-block">
          <p>
            Date:
            <span>
              {" "}
              {activity && activity[21].Value
                ? activity[21].Value.split(" ")[0]
                    .split("-")
                    .reverse()
                    .join("-")
                : ""}
            </span>
          </p>
          <p>
            Place: <span>{activity && activity[22].Value}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ApplicationDeclaration;
