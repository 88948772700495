import { Action } from '../actions/index'

function leadDetails(state, action) {
    let leadDetails = {
        firstName: '',
        lastName: '',
        email: ''
    }
    if (state) {
        switch (action.type) {
            case Action.LeadDetails.Update:
                leadDetails = Object.assign({}, state, {
                    firstName: action.leadDetails.firstName,
                    lastName: action.leadDetails.lastName,
                    email: action.leadDetails.email
                });
                break;
            default:
                leadDetails = state;
                break;
        }
    }
    return leadDetails;
}

export default leadDetails;