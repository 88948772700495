import logo from "../../images/logo.png";
import desktopBanner from "../../images/landing-banner.jpg";
import mobileBanner from "../../images/landing-mobile-banner.jpg";

const landingData = {
  title: "Begin your Application Process",
  subtitle:
    "Our courses are a unique qualification designed for practicing senior and middle-level managers",
  bannerURL: {
    desktop: desktopBanner,
    mobile: mobileBanner,
    alt: "Landing Banner",
  },
};

const appLogo = {
  path: logo,
  alt: "NIMS University",
};

export { landingData, appLogo };
