const FORM_PLACEHOLDER = {
  FNAME: "Enter First Name",
  LNAME: "Enter Last Name",
  NAME: "Enter  Name",
  EMAIL: "Enter Email Address",
  PASSWORD: "Enter Password",
  CONFIRM_PASSWORD: "Re-enter the password",
  PHONE_NUMBER: "Enter Phone Number",
  RESET_PASSWORD: "Enter New Password",
  RESET_CONFIRM_PASSWORD: "Confirm New Password",
};

export { FORM_PLACEHOLDER };
