import React from "react";
const AcademicQualication = ({ class10, class12, diploma, graduation }) => {
  return (
    <div className="application-academic-section">
      <p>Academic Qualification</p>
      <div className="application-details-block">
        {class10 && (
          <table>
            <thead>
              <tr>
                <th colSpan="2">High School (10th) / Equivalent</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>School Name </td>
                <td>{class10 && class10.mx_CustomObject_1}</td>
              </tr>
              <tr>
                <td>Board </td>
                <td>{class10 && class10.mx_CustomObject_2}</td>
              </tr>
              <tr>
                <td>CGPA/Percentage</td>
                <td>{class10 && class10.mx_CustomObject_3}</td>
              </tr>
              <tr>
                <td>Year Of Passing</td>
                <td>{class10 && class10.mx_CustomObject_4}</td>
              </tr>
            </tbody>
          </table>
        )}
        {class12 && (
          <table>
            <thead>
              <tr>
                <th colSpan="2">Intermediate (12th) / Equivalent</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>School Name </td>
                <td>{class12 && class12.mx_CustomObject_1}</td>
              </tr>
              <tr>
                <td>Stream </td>
                <td>{class12 && class12.mx_CustomObject_2}</td>
              </tr>
              <tr>
                <td>Board </td>
                <td>{class12 && class12.mx_CustomObject_3}</td>
              </tr>
              <tr>
                <td>CGPA/Percentage</td>
                <td>{class12 && class12.mx_CustomObject_4}</td>
              </tr>
              <tr>
                <td>Year Of Passing</td>
                <td>{class12 && class12.mx_CustomObject_5}</td>
              </tr>
            </tbody>
          </table>
        )}
        {graduation && (
          <table>
            <thead>
              <tr>
                <th colSpan="2">Graduation Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>College Name </td>
                <td>{graduation && graduation.mx_CustomObject_1}</td>
              </tr>
              <tr>
                <td>University Name</td>
                <td>{graduation && graduation.mx_CustomObject_2}</td>
              </tr>
              <tr>
                <td>Marks Obtainted</td>
                <td>{graduation && graduation.mx_CustomObject_3}</td>
              </tr>
              <tr>
                <td>Year of passing</td>
                <td>{graduation && graduation.mx_CustomObject_4}</td>
              </tr>
            </tbody>
          </table>
        )}
        {diploma && (
          <table>
            <thead>
              <tr>
                <th colSpan="2">Exam Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Entrance Test given by the App</td>
                <td>{diploma && diploma.mx_CustomObject_1}</td>
              </tr>
              <tr>
                <td>Year of Entrance Test </td>
                <td>{diploma && diploma.mx_CustomObject_2}</td>
              </tr>
              <tr>
                <td>Score</td>
                <td>{diploma && diploma.mx_CustomObject_3}</td>
              </tr>
              <tr>
                <td>Percentage</td>
                <td>{diploma && diploma.mx_CustomObject_4}</td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};
export default AcademicQualication;
