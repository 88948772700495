import React from "react";
import ApplicationPreviewHeader from "./ApplicationPreviewHeader";
import ApplicationDetails from "./ApplicationDetails";
import ParentDetails from "./ParentDetails";
import AddressDetails from "./AddressDetails";
import AcademicQualication from "./AcademicQualification";
import ApplicationDeclaration from "./ApplicationDeclaration";

// Helper Functions :
const createParentObject = (application) => {
  return {
    fatherName: application.mx_Father_Name,
    fatherMobile: application.mx_Father_Mobile_Number,
    fatherEmail: application.mx_Father_Email,
    fatherOccupation: application.mx_Occupation,
    motherName: application.mx_Mother_Name,
    motherMobile: application.mx_Mother_Mobile_Number,
  };
};

const addressNullCheck = (data) => {
  return data !== null ? data + "," : "";
};

const createAddressObject = (application) => {
  let cStreet1 = addressNullCheck(application.mx_Street1);
  let cStreet2 = addressNullCheck(application.mx_Street2);
  let cCity = addressNullCheck(application.mx_City);
  let cNState = addressNullCheck(application.mx_Corresponding_Address_State);
  let cState = addressNullCheck(application.mx_State);
  let cCountry = addressNullCheck(application.mx_Country);
  let cZip = addressNullCheck(application.mx_Zip);

  let pStreet1 = addressNullCheck(application.mx_Permanent_Address_1);
  let pStreet2 = addressNullCheck(application.mx_Permanent_Address_2);
  let pCity = addressNullCheck(application.mx_Permanent_Address_City);
  let pNState = addressNullCheck(application.mx_Permanent_Address_State);
  let pState = addressNullCheck(application.mx_Permanent_State);
  let pCountry = addressNullCheck(application.mx_Permanent_Country);
  let pZip = addressNullCheck(application.mx_Zip_Code);

  let cAddress = `${cStreet1} ${cStreet2} ${cCity} ${
    cCountry !== "India," ? cNState : cState
  } ${cCountry} ${cZip}`;

  let pAddress = `${pStreet1} ${pStreet2} ${pCity} ${
    pCountry !== "India," ? pNState : pState
  } ${pCountry} ${pZip}`;

  console.log(cAddress, "cAddress");
  console.log(pAddress, "pAddress");

  return {
    correspondanceAddress: {
      address: cAddress,
    },
    permanentAddress: {
      address: pAddress,
    },
  };
};

const ApplicationTemplate = ({ application, activity }) => {
  //Activity Field Data
  const activityData = activity.Fields && activity.Fields;
  console.log(activityData, "activityData");

  //Lead Field Data
  const applicationData = application && application;
  console.log(applicationData, "applicationData");

  //Parent Details Extracted from Lead
  const parentDetails = application && createParentObject(application);

  //Address Details Extracted from Lead
  const addressDetails = application && createAddressObject(application);

  // //Class 10 Details Extracted from Lead
  const class10Details =
    application &&
    application.mx_Class_10_Details &&
    JSON.parse(application.mx_Class_10_Details);

  //Class 12 Details Extracted from Lead
  const class12Details =
    application &&
    application.mx_Class_12_Details &&
    JSON.parse(application.mx_Class_12_Details);

  // Diploma Details Extracted from Lead
  const diplomaDetails =
    application &&
    application.mx_Exam_Details &&
    JSON.parse(application.mx_Exam_Details);

  // Graduation Details Extracted from Lead
  const graduationDetails =
    application &&
    application.mx_Graduation_Details &&
    JSON.parse(application.mx_Graduation_Details);

  return (
    <div className="application-template-wrapper">
      <ApplicationPreviewHeader />
      <div className="application-content-wrapper">
        <ApplicationDetails
          activity={activityData}
          application={applicationData}
        />
        <ParentDetails parentInfo={parentDetails} />
        <AddressDetails address={addressDetails} />
        <AcademicQualication
          class10={class10Details}
          class12={class12Details}
          diploma={diplomaDetails}
          graduation={graduationDetails}
        />
        <ApplicationDeclaration
          application={applicationData}
          activity={activityData}
          parentInfo={parentDetails}
        />
      </div>
    </div>
  );
};

export default ApplicationTemplate;
