import React from "react";

const LandingBanner = ({ data }) => {
  return (
    <>
      <section
        className="landing-banner"
        style={{ backgroundImage: `url(${data.bannerURL.desktop})` }}
      />
      <section
        className="landing-mobile-banner"
        style={{ backgroundImage: `url(${data.bannerURL.mobile})` }}
      />
    </>
  );
};

export default LandingBanner;
