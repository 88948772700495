import React, { useEffect, useState } from "react";
import {
  API_GetLeadsById,
  API_ActivityDetailsById,
} from "../../api/lead-activity-api";
import ApplicationTemplate from "./ApplicationTemplate";
import LoadingScreen from "../Loader/Loader";
import "./style.css";
import { PDFExport } from "@progress/kendo-react-pdf";

const ApplicationPreview = (props) => {
  const [applicationData, setApplicationData] = useState({});
  const [activityData, setActivityData] = useState({});
  const [isLoader, setIsLoader] = useState(true);

  // First Mount Call
  useEffect(() => {
    const activityIdFromParam = props.location.pathname.split("/").reverse()[0];

    API_GetLeadsById({
      leadId: props.authSession.leadId,
    })
      .then((response) => {
        const leadDetails = response.data && response.data[0];
        setApplicationData(leadDetails);
        API_ActivityDetailsById({ activityId: activityIdFromParam })
          .then((res) => {
            const activityDetails = res.data;
            const applicationId = activityDetails.Fields[19].Value;
            if (activityDetails && applicationId) {
              setActivityData(activityDetails);
              setIsLoader(false);
            } else {
              window.location.href = "/";
            }
          })
          .catch((error) => {
            window.location.href = "/";
            console.log(error, "activity API");
          });
      })
      .catch((error) => {
        window.location.href = "/";
        console.log(error, "Get Lead By Id erro");
      });
  }, []);

  const downloadPDF = () => {
    window.pdfExportComponent.save();
  };

  return (
    <>
      {isLoader ? (
        <LoadingScreen />
      ) : (
        <div className="application-preview-wrapper">
          <div className="application-preview-banner">
            <div className="container">
              <div
                className="btn btn-primary float-right"
                onClick={downloadPDF}
                style={{ cursor: "pointer" }}
              >
                Download PDF
              </div>
            </div>
          </div>
          <div className="container">
            <PDFExport
              forcePageBreak=".page-break"
              ref={(component) => (window.pdfExportComponent = component)}
              paperSize="A4"
              scale={0.5}
              margin="0.5cm"
              fileName={`NIMS-${activityData && activityData.Fields[19].Value}`}
            >
              <ApplicationTemplate
                application={applicationData}
                activity={activityData}
              />
            </PDFExport>
          </div>
        </div>
      )}
    </>
  );
};

export default ApplicationPreview;
