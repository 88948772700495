/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { API_GetLeadActivity } from "../../api/lead-activity-api";
import LoadingScreen from "../Loader/Loader";
import { getSessionData } from "../../utility/common";
import DashboardBanner from "./DashboardBanner";
import ApplicationCard from "./ApplicationCard";

const ApplicationHistory = (props) => {
  const [applicationData, setApplicationData] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [count, setCount] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const userInfo = getSessionData("user-info");
    setUserDetails(userInfo);
    API_GetLeadActivity({
      leadId: props.authSession.leadId,
      activityEvent: "220",
    })
      .then(function (response) {
        console.log(response.data, "Dashboard data");
        setApplicationData(response.data && response.data.ProspectActivities);
        setCount(response.data && response.data.RecordCount);
        setIsLoading(false);
      })
      .catch(function (error) {
        alert("Something Went Wrong!!!");
      });
  }, []);

  const renderApplication = (application, index) => {
    const activityId = application.Id ? application.Id : "";
    const lastUpdate = application.ModifiedOn ? application.ModifiedOn : "";
    const applicationId =
      application.ActivityFields && application.ActivityFields.mx_Custom_17
        ? application.ActivityFields.mx_Custom_17
        : "";
    const courseSelected =
      application.ActivityFields && application.ActivityFields.mx_Custom_4
        ? application.ActivityFields.mx_Custom_4
        : "";
    const applicationStatus =
      application.ActivityFields && application.ActivityFields.mx_Custom_16
        ? application.ActivityFields.mx_Custom_16
        : "";
    const applicationPaymentStatus =
      application.ActivityFields && application.ActivityFields.mx_Custom_15
        ? application.ActivityFields.mx_Custom_15
        : "";

    if (
      applicationStatus.toLowerCase() === "pending" &&
      applicationPaymentStatus.toLowerCase() === "unpaid"
    ) {
      return (
        <ApplicationCard
          courseTitle={courseSelected}
          applicationId={applicationId}
          courseStatus={applicationStatus}
          paymentStatus="unpaid"
          modifiedOn={lastUpdate}
          applicationCTA={{
            link: `/Home/Form/${activityId}`,
            text: "Continue Application",
          }}
        />
      );
    } else if (
      applicationStatus.toLowerCase() === "pending" &&
      applicationPaymentStatus.toLowerCase() === "paid"
    ) {
      return (
        <ApplicationCard
          courseTitle={courseSelected}
          applicationId={applicationId}
          courseStatus={applicationStatus}
          paymentStatus="paid"
          modifiedOn={lastUpdate}
          applicationCTA={{
            link: `/Home/Form/${activityId}`,
            text: "Continue Application",
          }}
        />
      );
    } else if (
      applicationStatus.toLowerCase() === "pending" &&
      applicationPaymentStatus.toLowerCase() === "payment in verification"
    ) {
      return (
        <ApplicationCard
          courseTitle={courseSelected}
          applicationId={applicationId}
          courseStatus={applicationStatus}
          paymentStatus="payment in verification"
          modifiedOn={lastUpdate}
          applicationCTA={{
            link: `/Home/Form/${activityId}`,
            text: "Continue Application",
          }}
        />
      );
    } else if (applicationStatus.toLowerCase() === "pending") {
      return (
        <ApplicationCard
          courseTitle={courseSelected}
          applicationId={applicationId}
          courseStatus={applicationStatus}
          modifiedOn={lastUpdate}
          applicationCTA={{
            link: `/Home/Form/${activityId}`,
            text: "Continue Application",
          }}
        />
      );
    } else if (
      applicationStatus.toLowerCase() === "in review" &&
      applicationPaymentStatus.toLowerCase() === "payment in verification" &&
      applicationId === ""
    ) {
      return (
        <ApplicationCard
          courseTitle={courseSelected}
          applicationId={applicationId}
          courseStatus={applicationStatus}
          paymentStatus="payment in verification"
          modifiedOn={lastUpdate}
        />
      );
    } else if (
      applicationStatus.toLowerCase() === "in review" &&
      applicationPaymentStatus.toLowerCase() === "payment in verification"
    ) {
      return (
        <ApplicationCard
          courseTitle={courseSelected}
          applicationId={applicationId}
          courseStatus={applicationStatus}
          paymentStatus="payment in verification"
          modifiedOn={lastUpdate}
          applicationCTA={{
            link: `/Home/ApplicationForm/Preview/${activityId}`,
            text: "View Application",
          }}
        />
      );
    } else if (
      applicationStatus.toLowerCase() === "in review" &&
      applicationPaymentStatus.toLowerCase() === "paid" &&
      applicationId === ""
    ) {
      return (
        <ApplicationCard
          courseTitle={courseSelected}
          applicationId={applicationId}
          courseStatus={applicationStatus}
          paymentStatus="paid"
          modifiedOn={lastUpdate}
        />
      );
    } else if (
      applicationStatus.toLowerCase() === "in review" &&
      applicationPaymentStatus.toLowerCase() === "paid"
    ) {
      return (
        <ApplicationCard
          courseTitle={courseSelected}
          applicationId={applicationId}
          courseStatus={applicationStatus}
          paymentStatus="paid"
          modifiedOn={lastUpdate}
          applicationCTA={{
            link: `/Home/ApplicationForm/Preview/${activityId}`,
            text: "View Application",
          }}
        />
      );
    } else if (
      applicationStatus.toLowerCase() === "in review" &&
      applicationPaymentStatus.toLowerCase() === "unpaid"
    ) {
      return (
        <ApplicationCard
          courseTitle={courseSelected}
          applicationId={applicationId}
          courseStatus={applicationStatus}
          paymentStatus="unpaid"
          modifiedOn={lastUpdate}
          applicationCTA={{
            link: `/Home/Form/${activityId}`,
            text: "Continue Application",
          }}
        />
      );
    } else if (
      applicationStatus.toLowerCase() === "application declined by university"
    ) {
      return (
        <ApplicationCard
          courseTitle={courseSelected}
          applicationId={applicationId}
          courseStatus={applicationStatus}
          modifiedOn={lastUpdate}
          applicationCTA={{
            link: `/Home/ApplicationForm/Preview/${activityId}`,
            text: "View Application",
          }}
        />
      );
    } else if (
      applicationStatus.toLowerCase() === "offer not accepted by student"
    ) {
      return (
        <ApplicationCard
          courseTitle={courseSelected}
          applicationId={applicationId}
          courseStatus={applicationStatus}
          modifiedOn={lastUpdate}
          applicationCTA={{
            link: `/Home/ApplicationForm/Preview/${activityId}`,
            text: "View Application",
          }}
        />
      );
    } else if (
      applicationStatus.toLowerCase() === "form filled and payment verified"
    ) {
      return (
        <ApplicationCard
          courseTitle={courseSelected}
          applicationId={applicationId}
          courseStatus={applicationStatus}
          modifiedOn={lastUpdate}
          applicationCTA={{
            link: `/Home/ApplicationForm/Preview/${activityId}`,
            text: "View Application",
          }}
        />
      );
    } else {
      return (
        <ApplicationCard
          courseTitle={courseSelected}
          applicationId={applicationId}
          courseStatus={applicationStatus}
          modifiedOn={lastUpdate}
          applicationCTA={{
            link: `/Home/Form/${activityId}`,
            text: "Continue Application",
          }}
        />
      );
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <div className="application-container">
          <DashboardBanner
            userDetails={userDetails}
            applyNow={
              <a
                href="/Home/Form"
                className="btn-primary"
                style={count === 0 ? { marginLeft: 10 } : { display: "none" }}
              >
                Apply Now
              </a>
            }
          />
          <div className="application-card-list">
            <div className="container">
              {
                applicationData.map((application, index) =>
                  renderApplication(application, index)
                )[0]
              }
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ApplicationHistory;
