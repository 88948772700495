import React, { useState } from "react";
import Timer from "react-compound-timer";
import OtpInput from "react-otp-input";
import { Modal } from "react-bootstrap";

const OtpScreen = ({
  type,
  show,
  value,
  onVerify,
  onResendOtp,
  onValueChange,
  error,
}) => {
  const [otp, setOtp] = useState("");
  const [resend, setResend] = useState(false);

  return (
    <Modal show={show} className="otp-modal">
      <div className="otp-screen">
        <h4>Verify OTP</h4>
        <p>
          A text with a One Time Password (OTP) has been sent to your {type}:{" "}
          <b>{value}</b>{" "}
          <button className="link-button" onClick={() => onValueChange()}>
            Change
          </button>
        </p>
        <div className="otp-box-wrapper">
          <OtpInput
            onChange={(val) => setOtp(val)}
            value={otp}
            numInputs={6}
            separator={<span>&nbsp;</span>}
            inputStyle="otp-box"
          />
        </div>
      </div>
      <div className="otp-button-wrapper">
        <button
          disabled={otp.length < 6}
          className="btn-primary submit-button"
          type="submit"
          onClick={() => {
            setOtp("");
            setResend(false);
            onVerify({ value, otp });
          }}
        >
          Submit
        </button>
      </div>
      <div className="otp-timer">
        {resend === true ? (
          <button
            className={`link-button`}
            disabled={!resend}
            onClick={() => {
              setOtp("");
              setResend(false);
              onResendOtp(value, type);
            }}
          >
            Resend
          </button>
        ) : (
          <Timer
            initialTime={120000}
            direction="backward"
            checkpoints={[
              {
                time: 0,
                callback: () => setResend(true),
              },
            ]}
          >
            {() => (
              <>
                <Timer.Minutes /> :
                <Timer.Seconds />
              </>
            )}
          </Timer>
        )}
      </div>
      {!!error && <div className="otp-error">{error}</div>}
    </Modal>
  );
};

export default OtpScreen;
