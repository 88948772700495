import React from "react";

const ApplicationDetails = ({ activity, application }) => {
  const applicatName =
    application &&
    application.FirstName +
      ` ` +
      (application.mx_Middle_Name !== null ? application.mx_Middle_Name : "") +
      ` ` +
      (application.LastName !== null ? application.LastName : "");

  return (
    <div className="application-basic-details-section">
      <div className="application-details-block">
        <table>
          <thead>
            <tr>
              <th colSpan="2">Application Details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Application ID</td>
              <td>{activity && activity[19].Value}</td>
            </tr>
            <tr>
              <td>College</td>
              <td>{activity && activity[5].Value}</td>
            </tr>
            <tr>
              <td>Course Name</td>
              <td>{activity && activity[6].Value}</td>
            </tr>
            <tr>
              <td>Academic Year</td>
              <td>{activity && activity[20].Value}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th colSpan="2">Applicant's Personal Details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Name</td>
              <td>{applicatName && applicatName}</td>
            </tr>
            <tr>
              <td>Gender</td>
              <td>{application && application.mx_Gender}</td>
            </tr>
            <tr>
              <td>Date Of Birth</td>
              <td>
                {application &&
                  application.mx_Date_Of_Birth &&
                  application.mx_Date_Of_Birth
                    .split(" ")[0]
                    .split("-")
                    .reverse()
                    .join("-")}
              </td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{application && application.EmailAddress}</td>
            </tr>
            <tr>
              <td>Phone No. </td>
              <td>{application && application.Phone}</td>
            </tr>
            <tr>
              <td>Nationality</td>
              <td>{application && application.mx_Nationality}</td>
            </tr>
            {application && application.mx_Aadhar_Number ? (
              <tr>
                <td>Aadhar Number</td>
                <td>{application && application.mx_Aadhar_Number}</td>
              </tr>
            ) : (
              ""
            )}
            {application && application.mx_Category ? (
              <tr>
                <td>Category</td>
                <td>{application.mx_Category}</td>
              </tr>
            ) : (
              ""
            )}
            {application && application.mx_Religion ? (
              <tr>
                <td>Category</td>
                <td>{application.mx_Religion}</td>
              </tr>
            ) : (
              ""
            )}
          </tbody>
        </table>
      </div>
      <div className="application-details-block">
        <div className="user-profile">
          <img
            src={
              activity &&
              activity[3].CustomObjectFormProperties.FieldProperties
                .FormMetaData[
                activity[3].CustomObjectFormProperties.FieldProperties
                  .FormMetaData.length - 1
              ].FileURL
            }
            alt="user"
            crossOrigin="anonymous"
          />
        </div>
      </div>
    </div>
  );
};

export default ApplicationDetails;
