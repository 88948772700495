import React from "react";
import LeadsquaredLogo from "../../images/powered-by.svg";
import "./style.css";

const PoweredByLeadSquared = () => {
  return (
    <footer className="footer-wrapper">
      Powered by <img src={LeadsquaredLogo} alt="Leadsquared" height="20" />
    </footer>
  );
};

export default PoweredByLeadSquared;
