import React from "react";
const parentInfo = ({ parentInfo }) => {
  return (
    <div className="application-academic-section">
      <p>Parent’s Information</p>
      <div className="application-details-block">
        <table>
          <thead>
            <tr>
              <th colSpan="2">Father’s Details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Name</td>
              <td>{parentInfo && parentInfo.fatherName}</td>
            </tr>
            {parentInfo && parentInfo.fatherMobile ? (
              <tr>
                <td>Phone Number</td>
                <td>{parentInfo && parentInfo.fatherMobile}</td>
              </tr>
            ) : (
              ""
            )}
            {parentInfo && parentInfo.fatherEmail ? (
              <tr>
                <td>Email</td>
                <td>{parentInfo && parentInfo.fatherEmail}</td>
              </tr>
            ) : (
              ""
            )}
            {parentInfo && parentInfo.fatherOccupation ? (
              <tr>
                <td>Occupation</td>
                <td>{parentInfo && parentInfo.fatherOccupation}</td>
              </tr>
            ) : (
              ""
            )}
          </tbody>
        </table>
        {parentInfo && parentInfo.motherName ? (
          <table>
            <thead>
              <tr>
                <th colSpan="2">Mother’s Details</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Name</td>
                <td>{parentInfo && parentInfo.motherName}</td>
              </tr>
              {parentInfo && parentInfo.motherMobile ? (
                <tr>
                  <td>Phone Number</td>
                  <td>{parentInfo && parentInfo.motherMobile}</td>
                </tr>
              ) : (
                ""
              )}
            </tbody>
          </table>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default parentInfo;
