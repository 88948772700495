var config = {
  AuthKey:
    "amc0OFRreENXdE5aMHZmQW1BMytnQXlWS3FNNlRuN3RSejMza3JlMXBxQythUmxNdk1FSjJGV1d4RVpXTDhIMDJJbUVTcFFsQVdDUUpDNHBKc1V2ZjdENUNLYVNLZVFqdEFCWXR6QjdrdnRXcXRxS1VROFBhU3JSblBoWjVNbHcrcGNVUWxxbG9PaWo5QW83bnpZMmlHRXRXby8yaXh4VU5EQ0I5YzI2b2dYeE5YcHJzbzFZYnBNMkZCZjlYdGlCcmN6UndRTGdsQ3BGMFErczdZNi9mUT09",
  AccessKey: "u$r9551fa65abc20ad830ccaa97e8d96552",
  SecretKey: "a4b98ded74a7de89281b6e569d6ce898063b24d2",
  portalBaseURL: "https://portalapi-in21.leadsquared.com/",
  lsqFormBaseURL: "https://in21.leadsquared.com",
  lsqFormURL:
    "https://dhx9mmhpfsala.cloudfront.net/cdn/externalforms/r21/js/lsq.form.js?v=61.3.d.0",
  lsqFormId: "0aaecd3d-cfe3-4567-a108-5e684cb95786",
  lsqFormProcessId: "b2064641-a3ee-11ea-bf5b-0a22ff7e4b86",
  lsqApplicationFeeFormURL:
    "https://dhx9mmhpfsala.cloudfront.net/cdn/externalforms/r21/js/lsq.form.js?v=61.3.d.0",
  lsqApplicationFeeFormId: "7a22a503-abf0-11ea-bf5b-0a22ff7e4b86",
  iframeURL:
    "https://application.nimsuniversity.org/iframe/src/application-form-preview.html",
};

export default config;
