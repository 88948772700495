import React, { useState, useEffect } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import LogoutContainer from "../../containers/LogoutContainer";
import { getSessionData } from "../../utility/common";

const UserProfile = () => {
  const [leadDetails, setLeadDetails] = useState();
  const placement = "bottom";

  useEffect(() => {
    const userInfo = getSessionData("user-info");
    console.log(userInfo)
    setLeadDetails(userInfo);
  }, []);

  return (
    <div
      id="userProfileOptions"
      className="user-profile-options"
      style={{ display: "flex", alignItems: "center" }}
    >
      <span
        style={{
          color: " #333",
          marginRight: 10,
          fontWeight: 500,
          fontSize: 14,
          textTransform: "capitalize",
        }}
      >
        {leadDetails &&
          (leadDetails.firstName
            ? `${leadDetails.firstName} ${
                leadDetails.lastName ? leadDetails.lastName : ""
              }`
            : leadDetails.email)}
      </span>
      <OverlayTrigger
        rootClose
        trigger={["focus", "click"]}
        key={placement}
        placement={placement}
        overlay={
          <Popover
            id={`popover-positioned-${placement}`}
            className="d-none d-lg-block"
            style={{
              marginRight: "60px",
              marginTop: "10px",
              borderRadius: "5px!important",
            }}
          >
            <div className="user-profile-content">
              <ul className="dropdown ">
                <li className="py-2">
                  <LogoutContainer />
                </li>
              </ul>
            </div>
          </Popover>
        }
      >
        <span className="user-profile-icon-wrapper">
          <i
            id="userProfileOptionsIcon"
            className="fa fa-user-circle-o"
            aria-hidden="true"
            style={{
              fontSize: 23,
              color: "#333",
            }}
          ></i>
        </span>
      </OverlayTrigger>
    </div>
  );
};

export default UserProfile;
