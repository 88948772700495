import axios from "axios";
import AuthHandler from "../utility/AuthHandler";
import { LSQ_API } from "../constants/endpoint";

var lsqApiInstance = axios.create({
  baseURL: LSQ_API.BASE,
  timeout: 10000,
  headers: { Authorization: AuthHandler.GetAuthKey() },
});

const API_GetLeadsById = (payload) => {
  return lsqApiInstance({
    method: "get",
    headers: "",
    url: LSQ_API.ENDPOINTS.GET_LEAD_BY_ID,
    params: {
      accessKey: AuthHandler.GetAccessKey(),
      secretKey: AuthHandler.GetSecretKey(),
      id: payload.leadId,
    },
  });
};

const API_GetLeadActivity = (payload) => {
  return lsqApiInstance({
    method: "post",
    headers: "",
    url: LSQ_API.ENDPOINTS.GET_LEAD_ACTIVITY,
    params: {
      accessKey: AuthHandler.GetAccessKey(),
      secretKey: AuthHandler.GetSecretKey(),
      leadId: payload.leadId,
    },
    data: {
      Parameter: {
        ActivityEvent: payload.activityEvent,
      },
    },
  });
};

const API_CreateActivity = (payload) => {
  return lsqApiInstance({
    method: "post",
    headers: "",
    url: LSQ_API.ENDPOINTS.CREATE_ACTIVITY,
    params: {
      accessKey: AuthHandler.GetAccessKey(),
      secretKey: AuthHandler.GetSecretKey(),
    },
    data: payload,
  });
};

const API_UpdateActivity = (payload) => {
  return lsqApiInstance({
    method: "post",
    headers: "",
    url: LSQ_API.ENDPOINTS.UPDATE_ACTIVITY,
    params: {
      accessKey: AuthHandler.GetAccessKey(),
      secretKey: AuthHandler.GetSecretKey(),
    },
    data: payload,
  });
};

const API_ActivityDetailsById = (payload) => {
  return lsqApiInstance({
    method: "get",
    headers: "",
    url: LSQ_API.ENDPOINTS.GET_ACTIVITY_DETAILS_BY_ID,
    params: {
      accessKey: AuthHandler.GetAccessKey(),
      secretKey: AuthHandler.GetSecretKey(),
      activityId: payload.activityId,
      getFileURL: true,
    },
  });
};

export {
  API_GetLeadsById,
  API_GetLeadActivity,
  API_UpdateActivity,
  API_ActivityDetailsById,
  API_CreateActivity,
};
