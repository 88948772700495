import { connect } from "react-redux";
import ApplicationSuccess from "../components/ApplicationSuccess";
import { withRouter } from "react-router-dom";

const mapStateToProps = (state) => {
  return {
    authSession: state.authSessionInfo,
  };
};

export default withRouter(connect(mapStateToProps)(ApplicationSuccess));
