/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { withRouter } from "react-router";
import queryString from "query-string";
import { FORM_PLACEHOLDER } from "../../constants/placeholder";
import { ERROR_MSG } from "../../constants/error";
import { passwordScheme } from "../../utility/ValidationSchema";
import AuthHandler from "../../utility/AuthHandler";

import Input from "../Input";
import Button from "../Button";
import OtpForm from "./OtpForm";
import {
  API_Register,
  API_otpGenerate,
  API_otpVerify,
  API_Login,
} from "../../api/signing-request";
import { API_GetLeadsById } from "../../api/lead-activity-api";

const RegisterForm = (props) => {
  const [name, setName] = useState("");
  const [lname, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phonenumber, setPhonenumber] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);

  const [passwordError, setPasswordError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [formMessage, setFormMessage] = useState("");

  const [otpEndpoint, setOtpEndpoint] = useState("");
  const [otpCode, setOtpCode] = useState("");
  const [otpGenerated, setOtpGenerated] = useState(false);
  const [otpVerfied, setOtpVerfied] = useState(false);

  const onGenerateOtp = (value, type) => {
    setVerifyLoading(true);
    if (value) {
      API_otpGenerate({
        LeadId: value,
        FormId: value,
        SchemaName: type,
        FieldDataType: type,
        FieldContent: value,
      })
        .then((res) => {
          if (res.data.Status === "Success") {
            setOtpCode(res.data.FormOTPResponseModel.Code);
            setOtpEndpoint(value);
            setOtpGenerated(true);
            setVerifyLoading(false);
          } else {
            setVerifyLoading(false);
            setFormMessage(ERROR_MSG.SERVER_ERROR);
          }
        })
        .catch((err) => setFormMessage(ERROR_MSG.SERVER_ERROR));
    } else {
      // setPhoneNumberError(ERROR_MSG.REQUIRED);
    }
  };

  const onVerifyOtp = ({ value, otp }) => {
    API_otpVerify({
      phone: value,
      otp: otp,
      code: otpCode,
    })
      .then((res) => {
        if (res.data.Status === "Success") {
          setOtpVerfied(true);
          setOtpGenerated(false);
        } else {
          setOtpError(res.data.Message);
        }
      })
      .catch((err) => setFormMessage(ERROR_MSG.SERVER_ERROR));
  };

  const onChangeNumber = () => {
    setOtpCode("");
    setOtpEndpoint("");
    setOtpGenerated(false);
    setOtpError("");
  };

  const checkFormData = () => {
    if (!passwordScheme(password)) {
      setPasswordError(ERROR_MSG.PASSWORD_PATTERN);
      return false;
    } else {
      setPasswordError("");
    }
    if (!phonenumber) {
      setPhoneNumberError(ERROR_MSG.REQUIRED);
      return false;
    } else {
      setPhoneNumberError("");
    }
    return true;
  };

  const resetForm = () => {
    setName("");
    setLName("");
    setEmail("");
    setPassword("");
    setPhonenumber("");

    setPasswordError("");
    setPhoneNumberError("");
    setOtpError("");

    setOtpEndpoint("");
    setOtpCode("");
    setOtpVerfied(false);
    setOtpGenerated(false);
  };

  const mobileNumberHandler = (telNumber, selectedCountry) => {
    let mobilecNumber = telNumber.replace(selectedCountry.dialCode, "");
    let mobile = mobilecNumber ? mobilecNumber.replace(/[^0-9]/g, "") : null;
    let mobileTel = "+" + selectedCountry.dialCode + "-" + mobile;
    setPhonenumber(mobileTel);
  };

  const onhandleSubmit = () => {
    const validation = checkFormData();

    if (validation) {
      const LeadFields = [];
      const utmValues = queryString.parse(window.location.search);

      // Register API Payload
      LeadFields.push({
        Attribute: "FirstName",
        Value: name,
      });
      LeadFields.push({
        Attribute: "LastName",
        Value: lname,
      });
      LeadFields.push({
        Attribute: "EmailAddress",
        Value: email,
      });
      LeadFields.push({
        Attribute: "mx_Portal_Password",
        Value: password,
      });
      LeadFields.push({
        Attribute: "mx_Portal_Confirm_Password",
        Value: password,
      });
      LeadFields.push({
        Attribute: "Phone",
        Value: phonenumber,
      });

      if (
        utmValues &&
        (utmValues.utm_campaign || utmValues.utm_medium || utmValues.utm_source)
      ) {
        LeadFields.push({
          Attribute: "Source",
          Value: utmValues
            ? utmValues.utm_source
              ? utmValues.utm_source
              : null
            : null,
        });
        LeadFields.push({
          Attribute: "SourceCampaign",
          Value: utmValues
            ? utmValues.utm_campaign
              ? utmValues.utm_campaign
              : null
            : null,
        });
        LeadFields.push({
          Attribute: "SourceMedium",
          Value: utmValues
            ? utmValues.utm_medium
              ? utmValues.utm_medium
              : null
            : null,
        });
      }

      setIsLoading(true);
      // Register API Call
      API_Register({
        LeadFields: LeadFields,
      })
        .then(function (response) {
          response && setFormMessage(response.data.Message);

          setIsLoading(false);
          //Success Message
          API_Login({ EmailAddress: email, Password: password })
            .then(function (response) {
              API_GetLeadsById({
                leadId: response.data.LeadId,
              })
                .then(function (res) {
                  const completeLeadDetails = res.data[0];

                  const authSessionInfo = {
                    isAuthenticated: true,
                    leadId: response.data.LeadId,
                    authKey: response.data.AuthKey,
                  };

                  if (completeLeadDetails) {
                    const leadDetails = JSON.stringify({
                      email: completeLeadDetails.EmailAddress,
                      firstName: completeLeadDetails.FirstName,
                      lastName: completeLeadDetails.LastName,
                    });
                    sessionStorage.setItem("user-info", leadDetails);
                  }

                  AuthHandler.SetAuthSessionInfoCookie(authSessionInfo);
                  props.updateLoginInfo(authSessionInfo);
                  props.history.push("/");
                  setIsLoading(false);
                })
                .catch(function (error) {
                  console.log(error, "error");
                });

              // for after signup success message
              localStorage.setItem("isSuccess", true);
            })
            .catch(function (error) {
              setIsLoading(false);
              setFormMessage(error.response.data.ExceptionMessage);
            });
        })
        .catch(function (error) {
          if (error.response.data.ExceptionMessage) {
            setFormMessage(error.response.data.ExceptionMessage);
          } else {
            setFormMessage("something went wrong");
          }

          setIsLoading(false);
        });

      // Resetting the Form and States
      resetForm();
    }
  };

  return (
    <>
      <div className="form-skeleton">
        {formMessage && <p className="form-message"> {formMessage}</p>}
        <form
          action=""
          method="post"
          className="form-wrapper-box"
          onSubmit={(e) => {
            e.preventDefault();
            onhandleSubmit();
          }}
        >
          <Input
            type="text"
            handleChange={(value) => {
              setName(value);
            }}
            handleBlur={() => {}}
            value={name}
            name="FirstName"
            error={false}
            label="First Name"
            mandatory={true}
            placeholder={FORM_PLACEHOLDER.FNAME}
          />
          <Input
            type="text"
            handleChange={(value) => {
              setLName(value);
            }}
            handleBlur={() => {}}
            value={lname}
            name="LastName"
            error={false}
            label="Last Name"
            mandatory={true}
            placeholder={FORM_PLACEHOLDER.LNAME}
          />
          <Input
            type="email"
            handleChange={(value) => {
              setEmail(value);
            }}
            handleBlur={() => {}}
            value={email}
            name="Email"
            error={false}
            label="Email"
            mandatory={true}
            placeholder={FORM_PLACEHOLDER.EMAIL}
          />
          <Input
            type="phone-verify"
            handleChange={(value, selectedCountry) => {
              mobileNumberHandler(value, selectedCountry);
            }}
            handleBlur={() => {}}
            value={phonenumber}
            name="Phone"
            error={phoneNumberError}
            label="Phone Number"
            mandatory={true}
            placeholder={FORM_PLACEHOLDER.EMAIL}
            verified={otpVerfied}
            onGenerateOtp={() => onGenerateOtp(phonenumber, "Phone")}
            status={verifyLoading}
          />
          <Input
            type="password"
            handleChange={(value) => {
              setPassword(value);
            }}
            handleBlur={() => {}}
            value={password}
            name="Password"
            error={passwordError}
            label="Password"
            mandatory={true}
            placeholder={FORM_PLACEHOLDER.PASSWORD}
          />
          <div className="form-group">
            <Button
              disabled={!otpVerfied}
              type="submit"
              className="btn-primary submit-button"
              text="Sign Up"
              status={isLoading}
            />
          </div>
        </form>
        <div className="form-footer">
          Already have an account?{"   "}
          <a onClick={() => props.changeWidgetState("LOGIN")}>Login</a>
        </div>
      </div>

      <OtpForm
        type="Phone Number"
        show={otpGenerated}
        value={otpEndpoint}
        onVerify={onVerifyOtp}
        otpVerifyCode={otpCode}
        onResendOtp={onGenerateOtp}
        onValueChange={onChangeNumber}
        error={otpError}
      />
    </>
  );
};

export default withRouter(RegisterForm);
