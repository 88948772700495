import React from "react";
import { useState } from "react";
import LoginFormContainer from "../../containers/LoginFormContainer";
import RegisterContainer from "../../containers/RegisterContainer";
import ForgotPasswordForm from "../PreLoginForms/ForgotPasswordForm";

const SigninWidget = ({ state, logo }) => {
  const [widgetState, setWidgetState] = useState(state);
  const changeWidgetState = (widgetState) => {
    setWidgetState(widgetState);
  };

  const renderForm = (state) => {
    switch (state) {
      case "REGISTER":
        return <RegisterContainer changeWidgetState={changeWidgetState} />;

      case "SIGNIN":
      case "LOGIN":
        return <LoginFormContainer changeWidgetState={changeWidgetState} />;

      case "FORGOTPASSWORD":
        return <ForgotPasswordForm changeWidgetState={changeWidgetState} />;

      default:
        return <RegisterContainer changeWidgetState={changeWidgetState} />;
    }
  };

  return (
    <>
      {widgetState === "REGISTER" ||
      widgetState === "LOGIN" ||
      widgetState === "SIGNIN" ? (
        <div className="widget-mobile-navigation">
          <ul>
            <li>
              <button
                className={`${widgetState === "REGISTER" && "active"}`}
                onClick={() => changeWidgetState("REGISTER")}
              >
                Sign Up
              </button>
            </li>
            <li>
              <button
                className={`${
                  (widgetState === "LOGIN" || widgetState === "SIGNIN") &&
                  "active"
                }`}
                onClick={() => changeWidgetState("LOGIN")}
              >
                Login
              </button>
            </li>
          </ul>
        </div>
      ) : null}
      <div className="form-container">{renderForm(widgetState)}</div>
    </>
  );
};

export default SigninWidget;
