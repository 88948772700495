const ERROR_MSG = {
  REQUIRED: "Required",
  PASSWORD_PATTERN:
    "Password should contain eight characters, at least one letter, one number and one special character",
  PASSWORD_MISMATCH: "Your password and confirmation password do not match",
  SERVER_ERROR: "Something went wrong. Please try again.",
  ALREADY_LOGIN: "You are Already Logged in",
};

export { ERROR_MSG };
