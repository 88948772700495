import { combineReducers } from 'redux';
import authSessionInfo from './auth-session-info';
import leadDetails from './lead-details';
import { Action } from '../actions';

const appReducer = combineReducers({
    authSessionInfo,
    leadDetails
});

const rootReducer = (state, action) => {
    if (action.type == Action.Logout.Request) {
        state = undefined;
    }
    return appReducer(state, action);
}

export default rootReducer;