import React from "react";

const AddressDetails = ({ address }) => {
  const permanentAddress =
    address && address.permanentAddress.address.trim("") === ""
      ? address.correspondanceAddress.address
      : address.permanentAddress.address;

  return (
    <div className="application-academic-section">
      <p>Address Details</p>
      <div className="application-details-block">
        <table>
          <tbody>
            {address && address.correspondanceAddress.address ? (
              <tr>
                <td>Present Address</td>
                <td>{address && address.correspondanceAddress.address}</td>
              </tr>
            ) : (
              ""
            )}
          </tbody>
        </table>
        {address && address.permanentAddress.address ? (
          <table>
            <tbody>
              {address && address.permanentAddress.address ? (
                <tr>
                  <td>Permanent Address</td>
                  <td>{permanentAddress}</td>
                </tr>
              ) : (
                ""
              )}
            </tbody>
          </table>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default AddressDetails;
