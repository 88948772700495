import React from "react";
import logo from "../../images/logo.png";

const ApplicationPreviewHeader = () => {
  return (
    <div className="college-branding">
      <div className="college-logo">
        <img src={logo} alt="NIMS" crossOrigin="anonymous" />
      </div>
      <div className="college-title">
        <h2>NIMS UNIVERSITY RAJASTHAN</h2>
        <p>Jaipur-Delhi Highway (NH-11C), Jaipur – 303121, Rajasthan, INDIA</p>
        <p>Call: +91-9116010414, 7412077155, 9116010433</p>
        <p>Email: admissions@nimsuniversity.org</p>
      </div>
    </div>
  );
};

export default ApplicationPreviewHeader;
