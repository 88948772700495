import React from "react";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import "./style.css";

const ApplicationCard = ({
  courseTitle,
  applicationId,
  courseStatus,
  paymentStatus,
  modifiedOn,
  applicationCTA,
}) => {
  const renderStatus = (status) => {
    switch (status) {
      case "paid": {
        return <div className="status-pill status-paid">Fee Paid</div>;
      }

      case "unpaid": {
        return <div className="status-pill status-unpaid">Unpaid</div>;
      }

      case "pending": {
        return <div className="status-pill status-pending">Pending</div>;
      }

      case "application submitted": {
        return (
          <div className="status-pill status-completed">
            Application Submitted
          </div>
        );
      }

      case "in review": {
        return <div className="status-pill status-completed">In Review</div>;
      }

      case "payment in verification": {
        return (
          <div className="status-pill status-completed">Payment in Review</div>
        );
      }

      case "course offered": {
        return <div className="status-pill status-pending">Offered</div>;
      }

      case "enrolled": {
        return <div className="status-pill status-completed">Enrolled</div>;
      }

      case "application declined by university": {
        return <div className="status-pill status-declined">Rejected</div>;
      }

      case "offer not accepted by student": {
        return <div className="status-pill status-declined">Not Accepted</div>;
      }

      case "rejected": {
        return <div className="status-pill status-declined">Rejected</div>;
      }

      case "form filled and payment verified": {
        return (
          <div className="status-pill status-completed">
            Form filled and Payment verified
          </div>
        );
      }

      default:
    }
  };
  const applicationModifiedFormat = (str) => {
    return moment(str).add(5.5, "h").format("DD MMMM YYYY, h:mma");
  };
  return (
    <div className="application-card" data-aos="fade-up" data-aos-delay="100">
      <div className="course-info">
        <p className="course-selected-title">
          {courseTitle ? courseTitle : "Please select a course"}
        </p>
        <p className="course-applied-id">
          {applicationId
            ? applicationId
            : "Application number is being generated"}
        </p>
      </div>
      <div className="application-course-status">
        <div className="application-payment-status">
          {courseStatus && renderStatus(courseStatus.toLowerCase())}
          {paymentStatus && renderStatus(paymentStatus.toLowerCase())}
        </div>
        <div className="application-modified-status">
          <p>
            Last Updated On:{" "}
            <span>{modifiedOn && applicationModifiedFormat(modifiedOn)}</span>
          </p>
        </div>
      </div>
      <div className={"application-cta"}>
        {applicationCTA && (
          <Link to={applicationCTA.link}>{applicationCTA.text}</Link>
        )}
      </div>
    </div>
  );
};

export default withRouter(ApplicationCard);
